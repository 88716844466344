<template>
  <v-row :no-gutters="$vuetify.breakpoint.xsOnly" align="start" class="my-2">
    <v-col cols="12" md="4" order-md="1" order-sm="2" sm="6">
      <kurcc-date-picker
        :date.sync="siteData.municipality_license_expire_date" :grow="!$vuetify.breakpoint.mdOnly"
        :label="$vuetify.lang.t('$vuetify.pages.site.form.fields.licenseExpireDate')"
        dense name="Municipality License Expire Date"/>
    </v-col>
    <v-col cols="12" md="4" order-md="2" order-sm="1" sm="6">
      <kurcc-image-input :image.sync="siteData.municipality_license_photo"
                         :name="$vuetify.lang.t('$vuetify.pages.site.form.fields.licensePhoto')" dense/>
    </v-col>
    <v-col v-if="imageSelected" cols="12" md="4" order-sm="3" sm="6">
      <kurcc-image-preview :src="siteData.municipality_license_photo" alt="municipality license"/>
    </v-col>
  </v-row>
</template>

<script>
import Site from '@/modules/app/models/site'

export default {
  name: 'KurccSiteStepperStep4Content',
  components: {
    KurccImageInput: () => import('@/modules/app/components/KurccImageInput'),
    KurccImagePreview: () => import('@/modules/app/components/KurccImagePreview'),
    KurccDatePicker: () => import('@/modules/app/components/KurccDatePicker')
  },
  props: {
    site: {
      type: Site,
      required: true
    }
  },
  computed: {
    siteData: {
      get () {
        return this.site
      },
      set (v) {
        this.$emit('update:site', v)
      }
    },
    imageSelected () {
      return this.siteData.municipality_license_photo
    }
  }
}
</script>
